import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {Fragment} from "react";
import {radii, RadiusBlock, Space, SpaceBlock, spacing} from "@/feature-docs/ThemeComponents.jsx";
function _createMdxContent(props) {
  return _jsxs(_Fragment, {
    children: [_jsx(Space, {
      space: "calc(var(--sp-1) * 100)",
      overrideDesc: "0.25rem * 100 = 25rem",
      value: "calc(var(--sp-1) * 100)"
    }), "\n", _jsx(Fragment, {
      children: Object.keys(spacing).map((key, i) => _jsx(SpaceBlock, {
        title: key,
        spacing: spacing[key]
      }, i))
    }), "\n", _jsx(RadiusBlock, {
      title: "Border Radius",
      spacing: radii
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
